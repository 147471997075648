const trapFocusHandlers = {};

export function onKeyUpEscape(event) {
    if (event.code.toUpperCase() !== 'ESCAPE') return;
  
    const openDetailsElement = event.target.closest('details[open]');
    if (!openDetailsElement) return;
  
    const summaryElement = openDetailsElement.querySelector('summary');
    openDetailsElement.removeAttribute('open');
    summaryElement.setAttribute('aria-expanded', false);
    summaryElement.focus();
  }


export function debounce(fn, wait) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  }

export function getFocusableElements(container) {
    return Array.from(
      container.querySelectorAll(
        "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe"
      )
    );
  }

export function removeTrapFocus(elementToFocus = null) {
    document.removeEventListener('focusin', trapFocusHandlers.focusin);
    document.removeEventListener('focusout', trapFocusHandlers.focusout);
    document.removeEventListener('keydown', trapFocusHandlers.keydown);
  
    if (elementToFocus) elementToFocus.focus();
  }

export function trapFocus(container, elementToFocus = container) {
    var elements = getFocusableElements(container);
    var first = elements[0];
    var last = elements[elements.length - 1];
  
    removeTrapFocus();
  
    trapFocusHandlers.focusin = (event) => {
      if (event.target !== container && event.target !== last && event.target !== first) return;
  
      document.addEventListener('keydown', trapFocusHandlers.keydown);
    };
  
    trapFocusHandlers.focusout = function () {
      document.removeEventListener('keydown', trapFocusHandlers.keydown);
    };
  
    trapFocusHandlers.keydown = function (event) {
      if (event.code.toUpperCase() !== 'TAB') return; // If not TAB key
      // On the last focusable element and tab forward, focus the first element.
      if (event.target === last && !event.shiftKey) {
        event.preventDefault();
        first.focus();
      }
  
      //  On the first focusable element and tab backward, focus the last element.
      if ((event.target === container || event.target === first) && event.shiftKey) {
        event.preventDefault();
        last.focus();
      }
    };
  
    document.addEventListener('focusout', trapFocusHandlers.focusout);
    document.addEventListener('focusin', trapFocusHandlers.focusin);
  
    elementToFocus.focus();
  
    if (
      elementToFocus.tagName === 'INPUT' &&
      ['search', 'text', 'email', 'url'].includes(elementToFocus.type) &&
      elementToFocus.value
    ) {
      elementToFocus.setSelectionRange(0, elementToFocus.value.length);
    }
  }

  export function toHTML(content) {
    let parsed = JSON.parse(content);
    let html = '';
    parsed.children.forEach((node) => {
      switch (node.type) {
        case 'heading':
          html += `<h${node.level}>${node.children[0].value}</h${node.level}>`;
          break;
        case 'list':
          html += `<${node.listType === 'unordered' ? 'ul' : 'ol'}>`;
          node.children.forEach((item) => {
            html += `<li>${item.children[0].value}</li>`;
          });
          html += `<${node.listType === 'unordered' ? '/ul' : '/ol'}>`;
          break;
        case 'paragraph':
          html += `<p>`;
          node.children.forEach((item) => {
            if (item.type === 'text' && item.bold) {
              html += `<strong>${item.value}</strong>` + ' ';
            } else if (item.type === 'text' && item.italic) {
              html += `<em>${item.value}</em>` + ' ';
            } else if (item.type === 'text') {
              html += `${item.value}` + ' ';
            }
            if (item.type === 'link' && item.bold) {
              html +=
                `<a href="${item.url}" target="${item.target}"><strong>${item.children[0].value}</strong></a>` +
                ' ';
            } else if (item.type === 'link' && item.italic) {
              html +=
                `<a href="${item.url}" target="${item.target}"><em>${item.children[0].value}</em></a>` +
                ' ';
            } else if (item.type === 'link') {
              html +=
                `<a href="${item.url}" target="${item.target}">${item.children[0].value}</a>` + ' ';
            }
          });
          html += `</p>`;
          break;
      }
    });
    return html;
  }
  